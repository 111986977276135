<template>
    <el-container class="container">
        <el-header>
            <industry-head></industry-head>
        </el-header>
        <el-main>
            <industry-main></industry-main>
        </el-main>
    </el-container>
</template>
<script>
import {IndustryMain,IndustryHead} from './components'
export default {
    name:'layout',
    components:{
       IndustryMain,
       IndustryHead
    }
}
</script>
<style scoped lang="scss">
.container{
    width: 100%;
    height: 100%;
    background: rgba(243, 247, 255, 1);
}
    .el-header{
        width: 100%;
        padding: 0 32px;
        box-sizing: border-box;
        height: 64px !important;
        box-shadow: 0px 1px 4px 3px rgba(0, 21, 41, 0.12);
        background: #fff;
    }
    .el-main{
        position: relative;
        padding: 0;
    }
</style>