<template>
  <div>
    <el-row>
      <el-col :span="20">
        <div class="logo">
          <img src="@/assets/img/logo.png" alt="" />
        </div>
      </el-col>
      <el-col v-if="$route.meta.title" :span="4" class="head-right">
        <el-row type="flex" justify="end" class="head-right-content">
          <el-col :span="2" class="head-right-i right-margin-27">
            <img src="@/assets/img/search.png" alt="" />
          </el-col>
          <el-col :span="2" class="head-right-i right-margin-17">
            <img src="@/assets/img/message.png" alt=""
          /></el-col>
          <el-col :span="12" class="head-user">
            <el-menu ref="menu" class="head-right-i" @select="handleSelect">
              <el-submenu index="2">
                <template slot="title"
                  ><span class="avatar"
                    ><img
                      :src="staticURL+userInfo.avatar"
                      alt=""
                      v-if="userInfo.avatar" /><img
                      v-else
                      src="@/assets/img/default_avatar.png"
                  /></span>
                  <span class="user">{{ userInfo.name }}</span></template
                >
                <el-menu-item index="2-1">退出登录</el-menu-item>
              </el-submenu>
            </el-menu>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { clearCookie } from "@/util/cookie";
import {URL} from '@/util/request'
export default {
  data() {
    return {
      userInfo: {},
      staticURL:URL.static
    };
  },
  watch: {
    $route() {
      if (!this.userInfo && localStorage.getItem("userInfo")) {
        this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      }
    },
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
  },
  methods: {
    handleSelect(key, keyPath) {
      localStorage.removeItem("token");
      this.$router.go(0);
      clearCookie('token');
      clearCookie('userInfo');
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  height: 64px;
  line-height: 64px;
  .el-col {
    height: 100%;
    .logo {
      width: 141px;
      height: 100%;
      img {
        width: 100%;
        vertical-align: middle;
      }
    }
  }
  .head-right {
    height: 100%;
    .head-right-content {
      .head-user {
        // width: 119px;
        width: auto;
      }
      .head-right-i,
      .head-right-avatar {
        margin-right: 27px;
        i {
          font-size: 18px;
          vertical-align: middle;
          color: rgba(55, 69, 103, 0.65);
        }
        > img {
          width: 100%;
          vertical-align: middle;
        }
      }
      .head-right-i:last-child {
        margin-right: 0;
      }
      .avatar {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        img {
          width: 100%;
          vertical-align: top;
        }
      }
      .user {
        font-size: 14px;
        color: rgba(55, 69, 103, 0.65);
        vertical-align: middle;
        margin-left: 8px;
      }
    }
  }
  /deep/.el-submenu__title {
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 !important;
    height: 100%;
    line-height: 64px;
    /deep/.el-submenu__icon-arrow {
      display: none;
    }
  }
  /deep/.el-submenu .el-menu-item {
    width: 100%;
    min-width: 100%;
    padding: 0 !important;
    text-align: center;
  }
  /deep/.el-menu--inline {
    margin-top: 1px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    width: 119px;
  }
  .el-menu {
    border-right: 0;
    z-index: 10;
    height: 100%;
    line-height: 64px;
    .el-submenu {
      height: 100%;
      line-height: 64px;
      .el-menu {
      }
    }
  }
}
</style>
